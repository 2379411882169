<template>
  <top></top>
  <div class="container">
    <div class="row contentTop">
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-3 leftBd">
        <ul>
          <dt @click="mechanismSj">机构介绍</dt>
          <dt @click="leader">领导介绍</dt>
          <dt @click="mechanism">专家介绍</dt>
          <dt @click="mechanismSetUp">机构设置</dt>
          <dd></dd>
          <li><img :src="erwei" class="img-responsive center-block" /></li>
          <li>电话:010-57108081</li>
        </ul>
      </div>
      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-9 minTop">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 newTop">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>专家介绍</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-sm-6 col-xs-6 activitybd xsBd"  v-for="item in newestList" :key="item.news_id">
            <li @click="newsInfo(item.news_id)">
                <img :src="item.news_img" class="img-responsive" />
                <dd>{{ item.news_title }}</dd>
            </li>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 fenye">
             <el-pagination  background layout="prev, pager, next"  :total="total"  @current-change="handleCurrentChange" :current-page="currentPage"></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <bottom></bottom>
</template>

<script>
import { post } from "@/http/api";
import Top from "./top.vue";
import bottom from "./bottom.vue";
import erwei from "@/assets/erwei.png";

export default {
  name: "newsList",
  components: {
    Top,
    bottom,
    erwei,
  },
  data() {
    return {
      erwei,
      newestList: [],
      total: "",
      pageSize: "",
      currentPage: "1",
      screenWidth: document.body.clientWidth,
    };
  },

  watch: {},

  created() {
    this.newestLists();
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },

  methods: {
    newestLists() {
      const qs = require("qs");
      let page = this.currentPage;
      post(
        "Index/mechanismLst",
        qs.stringify({ cate_id: this.$route.params.id, page: page })
      ).then((res) => {
        this.newestList = res.data.newsLst;
        this.total = res.data.newsNumber;
      });
    },

    newsInfo(news_id){
      this.$router.push({ path: `/newsInfo/${news_id}` });
      this.$forceUpdate();
    },

  
    //跳转机构介绍
    mechanismSj(){ 
      this.$router.push({ path: `/mechanismset` });
      this.$forceUpdate();
    },

    //跳转领导介绍
    leader(){ 
      this.$router.push({ path: `/Leader` });
      this.$forceUpdate();
    },

    //跳转机构设置
    mechanismSetUp(){ 
      this.$router.push({ path: `/Mechanismsetup` });
      this.$forceUpdate();
    },


    //跳转专家介绍
    mechanism(){
      this.$router.push({ path: `/Mechanism` });
      this.$forceUpdate();
    },

    handleCurrentChange(current) {
      this.currentPage = current;
      this.newestLists();
    },
  },
};
</script> 
<style scoped>
@media (max-width: 768px) {
 /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 3px;
    font-size: 1rem;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    font-weight: normal;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 0px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 0px;
    text-align: center;
    font-size: 1rem;
  }
  .leftBd ul li img {
    width: 80%;
  }
  :deep .el-breadcrumb {
    font-size: 1rem;
    line-height: 1;
  }
  /* 右侧 */
  .minTop{min-height: 600px}
  .newTop {
    margin-bottom: 2%;
  }
   /* 活动参展 */
  .activityTop {
    margin-top: 1%;
  }
  .activitybd li {
    border: 1px solid #04040429;
    list-style: none;
    border-radius: 5px;
    margin-bottom: 8%;
    cursor: pointer;
  }
  .activitybd li dd {
    font-size: 1rem;
    padding: 3px;
    color: #666666;
    text-align: center;
  }
  .pingtaibd {
    margin-top: 1%;
    cursor: pointer;
  }
  .pingtaibd img {
    width: 40%;
  }
  .pingtaibd dd {
    text-align: center;
    font-size: 14px;
    padding-top: 3px;
    color: #4d4d4d;
  }
  .fenye{
    margin-top: 2%;
  }
  .xsBd{padding-right: 5px;padding-left: 5px;}
}

@media (min-width: 768px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd{
    padding-left:5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop{min-height: 600px}
  .newTop {
    margin-bottom: 2%;
  }
   /* 活动参展 */
  .activityTop {
    margin-top: 1%;
  }
  .activitybd li {
    border: 1px solid #04040429;
    list-style: none;
    border-radius: 5px;
    margin-bottom: 8%;
    cursor: pointer;
  }
  .activitybd li dd {
    font-size: 14px;
    padding: 5px;
    color: #666666;
  }
  .pingtaibd {
    margin-top: 1%;
    cursor: pointer;
  }
  .pingtaibd img {
    width: 40%;
  }
  .pingtaibd dd {
    text-align: center;
    font-size: 14px;
    padding-top: 3px;
    color: #4d4d4d;
  }
  .fenye{
    margin-top: 2%;
  }
}

@media (min-width: 992px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd{
    padding-left:5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop{min-height: 600px}
  .newTop {
    margin-bottom: 2%;
  }
   /* 活动参展 */
  .activityTop {
    margin-top: 1%;
  }
  .activitybd li {
    border: 1px solid #04040429;
    list-style: none;
    border-radius: 5px;
    margin-bottom: 8%;
    cursor: pointer;
  }
  .activitybd li dd {
    font-size: 14px;
    padding: 5px;
    color: #666666;
  }
  .pingtaibd {
    margin-top: 1%;
    cursor: pointer;
  }
  .pingtaibd img {
    width: 40%;
  }
  .pingtaibd dd {
    text-align: center;
    font-size: 14px;
    padding-top: 3px;
    color: #4d4d4d;
  }
  .fenye{
    margin-top: 2%;
  }
}

@media (min-width: 1200px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd{
    padding-left:5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop{min-height: 600px}
  .newTop {
    margin-bottom: 2%;
  }
   /* 活动参展 */
  .activityTop {
    margin-top: 1%;
  }
  .activitybd li {
    border: 1px solid #04040429;
    list-style: none;
    border-radius: 5px;
    margin-bottom: 8%;
    cursor: pointer;
  }
  .activitybd li dd {
    font-size: 14px;
    padding: 5px;
    color: #666666;
  }
  .pingtaibd {
    margin-top: 1%;
    cursor: pointer;
  }
  .pingtaibd img {
    width: 40%;
  }
  .pingtaibd dd {
    text-align: center;
    font-size: 14px;
    padding-top: 3px;
    color: #4d4d4d;
  }
  .fenye{
    margin-top: 2%;
  }
}
</style>